import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Landing from './pages/Landing.vue';
import Login from './pages/Login.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import evento from './pages/evento.vue'
import compras from './pages/compras/ingresso.vue'
Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [

    {
      path: "/",
      redirect: "/",
      component: () => import("@/pages/Layout.vue"),
      children: [
        {
          path: "/",
          name: "index",
          components: { default: Index, header: MainNavbar, footer: MainFooter },
          props: {
            header: { colorOnScroll: 400 },
            footer: { backgroundColor: 'black' }
          }
        },
        {
          path: '/landing',
          name: 'landing',
          components: { default: Landing, header: MainNavbar, footer: MainFooter },
          props: {
            header: { colorOnScroll: 400 },
            footer: { backgroundColor: 'black' }
          }
        },
        {
          path: '/login',
          name: 'login',
          components: { default: Login, header: MainNavbar },
          props: {
            header: { colorOnScroll: 400 }
          }
        },
        {
          path: '/profile',
          name: 'profile',
          components: { default: Profile, header: MainNavbar, footer: MainFooter },
          props: {
            header: { colorOnScroll: 400 },
            footer: { backgroundColor: 'black' }
          }

        },
        {
          path: '/compras',
          name: 'compras',
          components: { default: compras, header: MainNavbar, footer: MainFooter },
          props: {
            header: { colorOnScroll: 400 },
            footer: { backgroundColor: 'black' }
          }

        },
        {
          path: '/:nome?/:id?',
          name: 'evento',
          components: { default: evento, header: MainNavbar, footer: MainFooter },
          props: {
            header: { colorOnScroll: 400 },
            footer: { backgroundColor: 'black' }
          }

        },
       
      ]
    }

  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
