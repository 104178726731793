/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import './registerServiceWorker'
import NowUiKit from './plugins/now-ui-kit';
import VueLazyload from 'vue-lazyload';
import "@/core/plugins/vuemask";
import VueSimpleAlert from "vue-simple-alert";
// import VueConfirmDialog from 'vue-confirm-dialog'
Vue.config.productionTip = false
import "@/core/plugins/bootstrap-vue";
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

Vue.use(NowUiKit);
Vue.use(VueLazyload);
// Vue.use(VueConfirmDialog);
Vue.use(VueSimpleAlert);
Vue.component('bbt')

import store from "@/core/store";

import ApiService from "@/core/services/api.service";
ApiService.init();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");