import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export const SET_LISTA_CLIENTES = "setListUsuariosMatriculas"
export const SET_LISTA_USUARIOS_PESQUISA = "setListUsuariosPesquisa"
export const SET_LISTA_CLIENTE = "setListMatriculasUsuarios"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_COUNT_CLIENTE = 'setCountCliente'
export const SET_LISTA_TIPO_BENEFICIADO  = 'setListTipoBeneficiado'


const actions = {
    async listar_cliente(context, value) {
        let message = await ApiService.get('cliente/'+value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async create_cliente(context, value) {
        let message = await ApiService.post('cliente', value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    async update_cliente(context, value) {
        let message = await ApiService.put('cliente/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    // async listar_usuarios(context, params) {
    //     await ApiService.get('usuario', {params: params})
    //         .then(response => context.commit(SET_LISTA_CLIENTES, response.data))
    //         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'error', message: error.response.data
    //         }))
    // },

    // async listar_usuario(context, id) {
    //     await ApiService.get('cliente/usuario/' + id)
    //         .then(response =>
    //             context.commit(SET_LISTA_CLIENTES, response.data))
    //         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'error', message: error.response.data
    //         }))
    // },
    
    async recuperar_senha(context, value) {
        let message = await ApiService.post('cliente/recuperar-senha',value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    
    
    async troca_senha(context, value) {
        let message = await ApiService.put('usuario/trocar/senha/'+value.id, value)
            .then(response => ({tipo: 'success', message: response.data}))
            .catch((error) => ({tipo: 'error', message: error.response.data}))
        context.commit(SET_MESSAGE_ALERT, message)
    },
    

    async listar_tipo_beneficiado(context) {
        await ApiService.get('cliente/tipo_beneficiado')
        .then(response => {
            context.commit(SET_LISTA_TIPO_BENEFICIADO, response.data)
        })
        .catch((error) => {
            context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            })
        })
    },
    // async count(context) {
    //     await ApiService.get('usuariocount')
    //         .then(response => context.commit(SET_COUNT_CLIENTE, response.data))
    //         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'error', message: error.response.data
    //         }))
    // },
};

const getters = {};

const mutations = {
    // [SET_LISTA_CLIENTES](state, value) {
    //     state.lista_usuarios = value
    // },
 
  
    [SET_MESSAGE_ALERT](state, value) {
      console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
          conteudo:  value.message.conteudo,
        };
      },

    [SET_LISTA_TIPO_BENEFICIADO](state,value){
        state.lista_tipo_beneficiado = value
    }
  
      
};

const state = {
    // lista_usuarios: [],
    // lista_usuario: [],
    // count_cliente:{}
    lista_tipo_beneficiado:[],
    mensagem_alert:''
};

export default {
    namespaced:true,
    state,
    actions,
    mutations,
    getters
};