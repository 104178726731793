
import ApiService from "@/core/services/api.service";


export const SET_LISTA_EVENTO = "setListeventoEventoRev";
export const SET_EVENTO = "setEvento";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_MSN_COMPRA = 'setMsnCompra'
export const SET_LISTA_CATEGORIAS = "setListCategoria";
export const SET_CEP = "setCep";
export const INGRESSO_CLIENTE = "setIngressoCliente";
export const INGRESSO_PIX = "setIngressoPix"



const actions = {
    
  
    async listar_eventos_carnaval(context) {
        await ApiService.get('evento/site/carnaval')
            .then(response => {
                context.commit(SET_LISTA_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    
    async listar_eventos(context) {
        await ApiService.get('evento/site/'+1)
            .then(response => {
                context.commit(SET_LISTA_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_evento(context,value) {
        await ApiService.get('evento/site/'+value.nome+'/'+value.id)
            .then(response => {
                context.commit(SET_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_evento_produtor(context,value) {
        await ApiService.get('produtor/site/'+value.nome+'/'+value.id)
            .then(response => {
                context.commit(SET_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    
     listar_evento_limpar(context) {
        context.commit(SET_EVENTO,{})

    },



    async listar_categorias(context) {
        await ApiService.get('site/categorias')
            .then(response => {
                context.commit(SET_LISTA_CATEGORIAS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    
    
    async analits(context,value) {
        await ApiService.post('analits', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },
    async analitsEvento(context,value) {
        await ApiService.post('analits/evento', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },
    async set_msn_compra(context,value) {
        context.commit(SET_MSN_COMPRA,value)
    },
    async cep(context,value) {
        await ApiService.get('cep/'+value)
        .then(response => {
            context.commit(SET_CEP, response.data)
        })
        .catch((error) => {
            context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            })
        })
    },
    async listar_ingressos_cliente(context,value) {
        await ApiService.get('ingresso/cliente/'+value)
        .then(response => {
            context.commit(INGRESSO_CLIENTE, response.data)
        })
        .catch((error) => {
            context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            })
        })
    },

    async comprar_ingresso(context,value) {
        await ApiService.post('fatura/ingresso',value)
        .then(response => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'success',
            message: response.data
        }))
        .catch((error) => context.commit(SET_MESSAGE_ALERT, {
            tipo: 'error',
            message: error.response.data
        }))
    },
    
    async  listar_ingressos_pix(context) {
        await ApiService.get('fatura/pix')
        .then(response => {
            context.commit(INGRESSO_PIX, response.data)
        })
        .catch((error) => {
            context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            })
        })
    },
    
   
    


};
const getters = {};

const mutations = {
    [SET_LISTA_EVENTO](state, value) {
        state.lista_eventos = value;
    },
    [SET_MSN_COMPRA](state, value) {
        state.msn_compra = value;
    },

    [SET_LISTA_CATEGORIAS](state, value) {
        state.lista_categorias = value;
    },
    [SET_EVENTO](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    
    [INGRESSO_PIX](state,value){
        state.ingresso_pix = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },
      [SET_CEP](state,value){
      state.cep = value
      },
      [INGRESSO_CLIENTE](state,value){
        state.ingressos_cliente = value
    },

};

const state = {
    lista_categorias:[],
    lista_eventos: [],
    lista_eventos_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",
    msn_compra:'',
    cep:"",
    ingressos_cliente:[],
    ingresso_pix:[]

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
