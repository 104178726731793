
import ApiService from "@/core/services/api.service";
export const SET_BAR = "setEvento";
export const SET_MESSAGE_ALERT = "setMessageAlert"
const actions = {
    
  
    async setbar(context) {
        await ApiService.get('bar/'+1)
            .then(response => {
                context.commit(SET_BAR, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


};
const getters = {};

const mutations = {
    [SET_BAR](state, value) {
        state.bar = value;

    },
  
   
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },
   

};

const state = {
    bar: {},
    mensagem_alert: "",


};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
