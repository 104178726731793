<template>
  <div id="app">
   
      <router-view />
  
  </div>
</template>
<script>
export default {};
</script>
<style>
.font-weight-700{
  font-weight: 700;
}
</style>
