<template>
  <div class="page-header clear-filter" filter-color="black">
    <div
      class="page-header-image"
      style="background-image: url('04.png')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>
            <!-- <div slot="header" class="logo-container">
              <img v-lazy="'logo.png'" alt="" />
            </div> -->

            <fg-input
              class="input-lg "
              addon-left-icon="now-ui-icons users_circle-08"
              placeholder="Email..."
              v-model="form_login.email"

              :class="
                form_login.email == ''
                  ? validate_form_login.email
                  : ' has-success'
              "
         
            >
            </fg-input>

            <fg-input
            v-if="!esqsenha"
              class="input-lg"
              addon-left-icon="now-ui-icons business_badge"
              placeholder="Senha..."
              type="password"
           
                 v-model="form_login.password"
              :class="
                form_login.password == ''
                  ? validate_form_login.password
                  : ' has-success'
              "
            >
            </fg-input>

            <template slot="raw-content">
              <div v-if="!esqsenha" class="card-footer text-center">
                <a
                @click="validate2()"
                  class="btn btn-primary btn-round btn-lg btn-block"
                  >Login</a
                >
              </div>
              <div v-if="!esqsenha" class="pull-left">
                <h6>
                  <a  @click="modalCadastro = true" class="link footer-link">Não tem conta ainda?</a>
                </h6>
              </div>
              <div v-if="!esqsenha" class="pull-right">
                <h6>
                  <a  @click="esqsenha = true" class="link footer-link">Esqueceu Sua senha?</a>
                </h6>
              </div>
              <div v-if="esqsenha" class="card-footer text-center">
                <a
                @click="passemail()"
                  class="btn btn-primary btn-round btn-md btn-block"
                  >Enviar Email de Recuperação</a
                >
                <a
                @click="esqsenha = false"
                  class="btn btn-danger btn-round btn-sm btn-block"
                  >Cancelar</a
                >
              </div>
              

                <!-- <div v-if="esqsenha" class="pull-right">
            <n-button class="mr-2 mt-3" @click="passemail()" variant="success"
              >Enviar Email de Recuperação</b-button
            >
            <n-button class="mr-2 mt-3"   @click="esqsenha = false" variant="success"
              >Cancelar</b-button
            >
          </div> -->

            </template>
          </card>
        </div>
      </div>
    </div>

    <modal
    v-if="modalCadastro" :show.sync="modalCadastro"
    style="font-weight: 700 "
    

    headerClasses="p-3"
    >
    <h6 style="color: black;" slot="header" class="modal-title" id="modal-title-default">
        CADASTRO DE CLIENTES
      </h6>

    <div style="overflow:scroll; height:530px; width: 100%; color: black; ">

  
      <div class="form col-12 col-lg-11">
        <div class="row form-group">
          <div class="col-md-12">
            <label>Email:*</label>
            <fg-input
              required=""
              placeholder="Seu E-mail"
              v-model="form.email"
              class=" input-lg"
              :class="
                form.email == confirme_email
                  ? ' has-success'
                  : ' has-danger'
              "
            />
          </div>
          <div class="col-md-12">
            <label>Confirme seu email:*</label>
            <fg-input
              required=""
              placeholder="Seu E-mail"
              v-model="confirme_email"
              class=" input-lg"
              :class="
                form.email == confirme_email
                  ? ' has-success'
                  : ' has-danger'
              "
            />
          </div>
          <div class="col-md-6">
            <label>Senha:*</label>
            <fg-input
              required=""
              type="text"
              placeholder="Sua senha"
              v-model="form.password"
              :class="
                confirme_password == form.password
                  ? ' has-success'
                  : ' has-danger'
              "
              class=" input-lg"
            />
          </div>
          <div class="col-md-6">
            <label>Confirme sua Senha:*</label>
            <fg-input
              required=""
              type="password"
              placeholder="Sua senha"
              v-model="confirme_password"
              :class="
                confirme_password == form.password
                  ? ' has-success'
                  : ' has-danger'
              "
              class=" input-lg"
            />
          </div>
          <div class="col-md-6">
            <label>Nome:*</label>
            <fg-input
              required=""
              placeholder="O seu Nome"
              v-model="form.nome"
              :class="
                form.nome == '' ? validate_form.nome : ' has-success'
              "
              class=" input-lg"
            />
          </div>
          <div class="col-md-6">
            <label>Sobrenome:*</label>
            <fg-input
              required=""
              placeholder="O seu Sobrenome"
              v-model="form.sobrenome"
              :class="
                form.sobrenome == ''
                  ? validate_form.sobrenome
                  : ' has-success'
              "
              class=" input-lg"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-6">
            <label for="cpf">CPF*</label>
            <fg-input
              required=""
              type="text"
              class=" input-lg"
              id="cpf"
              v-mask="variableWithMask2"
              v-model="cpf_cnpj2"
              :class="
                form.cpf == '' ? validate_form.cpf : ' has-success'
              "
              placeholder="O cpf ou Cnpj"
            />
          </div>
          <div class="col-md-6">
            <label for="telefone1">WhatsApp :*</label>
            <span v-if="radio == 1" style="font-size: 9px"
            >Obs: O 9 a mais já é colocado automaticamente
            </span>
            <fg-input
              required=""
              type="text"
              class=" input-lg"
              id="telefone1"
              v-mask="variableWithMaskTel"
              :class="
                form.telefone1 == ''
                  ? validate_form.telefone1
                  : ' has-success'
              "
              v-model="tel1"
              placeholder="O telefone/celular..."
            />
            <!-- <b-form-radio-group
              @input="ra"
              v-model="radio"
              label=""
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="1"
                >Brasil +55</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="2"
                >Paraguai +595</b-form-radio
              >
            </b-form-radio-group> -->
          </div>
          <div class="col-md-3 mt-2">
            <label> CEP:  </label>
            <fg-input
              placeholder="Digite seu cep"
              type="text"
              v-model="form.cep"
              :class="
                form.cep == '' ? validate_form.cep : ' has-success'
              "
              v-mask="'########'"
              class=" input-lg"
              @input="cepinput"
            />
            <span style="font-size: 10px;"> obs:  Somente Numeros</span>
          </div>
        

          <div class="col-md-3 mt-2">
            <label> UF:</label>
            <fg-input
              placeholder="Digite seu uf"
              v-model="form.uf"
              :class="
                form.uf == '' ? validate_form.uf : ' has-success'
              "
              class=" input-lg"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label> Cidade:</label>
            <fg-input
              placeholder="Digite sua cidade"
              v-model="form.cidade"
              :class="
                form.cidade == ''
                  ? validate_form.cidade
                  : ' has-success'
              "
              class=" input-lg"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label> Bairro:</label>
            <fg-input
              placeholder="Digite seu bairro"
              v-model="form.bairro"
              :class="
                form.bairro == ''
                  ? validate_form.bairro
                  : ' has-success'
              "
              class=" input-lg"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label> Rua:</label>
            <fg-input
              placeholder="Digite seu rua"
              v-model="form.rua"
              :class="
                form.rua == '' ? validate_form.rua : ' has-success'
              "
              class=" input-lg"
            />
          </div>
          <div class="col-md-3 mt-2">
            <label> Numero:</label>
            <fg-input
              placeholder="Digite numero do endereço"
              v-model="form.numero"
              :class="
                form.numero == ''
                  ? validate_form.numero
                  : ' has-success'
              "
              class=" input-lg  "
            />
          </div>
        </div>
      </div>

    
      </div>
      <n-button
        :disabled="confirme_password != form.password"
        class="pull-right mr-5 mt-3 btn-info"
        @click.prevent="validate()"
        
        >Salvar</n-button
      >
      <template slot="footer">
        <n-button
          size="sm"
          type="link"
          class="ml-auto"
          @click="modalCadastro = false"
          >Cancelar
        </n-button>
      </template>
    </modal>
    <modal
      v-if="modalLogin" :show.sync="modalLogin"

      classe="p-3"
      style="font-weight: 900"
    >
      <h6 slot="header" class="modal-title" id="modal-title-default">
        Login do cliente
      </h6>

      <div class="form">
        <div class="row form-group">
          <div class="col-md-6">
            <label>Email:*</label>
            <fg-input
              required=""
              placeholder="Seu E-mail"
              v-model="form_login.email"
              class=" input-lg"
              :class="
                form_login.email == ''
                  ? validate_form_login.email
                  : ' has-success'
              "
            />
          </div>

          <div class="col-md-6">
            <label>password:*</label>
            <fg-input
              required=""
              type="password"
              placeholder="Sua senha"
              v-model="form_login.password"
              :class="
                form_login.password == ''
                  ? validate_form_login.password
                  : ' has-success'
              "
              class=" input-lg"
            />
          </div>
        </div>
      </div>

      <n-button
        class="pull-right mr-5 mt-3"
        @click="validate2()"
        variant="success"
        >Logar</n-button
      >

      <template slot="footer">
        <n-button
          size="sm"
          type="link"
          class="ml-auto"
          @click="modalCadastro = false"
          >Cancelar
        </n-button>
      </template>
    </modal>
    
    <main-footer></main-footer>
    
  </div>
</template>
<script>
import { Card, Button, FormGroupInput,Modal } from '@/components';
import MainFooter from '@/layout/MainFooter';
import usuarioService from "@/core/services/usuario.service";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],

  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Modal
  },
  data() {
    return {
      modalCompraPix: null,
      confirme_password: null,
      modalVendas: false,
      modalCadastro: false,
      modalLogin: false,
      modalCompra: false,
      verif: false,
      ingresso: {},
      items: [],
      quant: [],
      form: {
        email: "",
        nome: "",
        sobrenome: "",
        cpf: "",
        password: "",
        telefone1: "",
        cep: "",
        rua: "",
        numero: "",
        bairro: "",
        uf: "",
        cidade: "",
      },
      variableWithMask2: "",
      validate_form: {
        email: null,
        nome: null,
        sobrenome: null,
        cpf: null,
        password: null,
        telefone1: null,
        cep: null,
        rua: null,
        numero: null,
        bairro: null,
        cidade: null,
        uf: null,
      },

      form_login: {
        email: "",
        password: "",
      },
      validate_form_login: {
        email: null,
        password: null,
      },

      form_compra: {
        name: "",
        expiration: "",
        number: "",
        cvv: "",
      },
      validate_form_compra: {
        name: null,
        expiration: null,
        number: null,
        cvv: null,
      },
      radio: 1,
      variableWithMaskTel: "",
      tel1: null,
      cpf_cnpj2: null,
      usu_logado: usuarioService.getUsuario() ?? null,
      confirme_email: null,
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      options: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      },
      subTotal: 0,
      esqsenha:false
    };
  },
  watch:{
    cpf_cnpj2: function () {
      this.variableWithMask2 =
        this.cpf_cnpj2.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form.cpf = this.cpf_cnpj2.replace(/[^0-9]/g, "");
    },
    tel1: function () {
      this.variableWithMaskTel =
        this.radio == 1 ? "(##)9####-####" : "+595-###-######";

      if (this.radio == 1) {
        this.form.telefone1 = this.tel1.replace(/[^0-9]/g, "");
        this.form.ddd = this.form.telefone1.substring(2, 4);
      } else {
        this.form.telefone1 = this.tel1.replace(/[^0-9]/g, "");
      }
    },
  },
  computed: {
    mensagem_alert() {
      return this.$store.state.cliente.mensagem_alert;
    },
       cep() {
      return this.$store.state.evento.cep;
    },
  },
  methods: {
    async passemail() {
      await this.$store.dispatch("cliente/recuperar_senha", this.form_login);
      this.fireAlert({
        ...this.mensagem_alert,
      });

      this.esqsenha = false;
    },
    route(route) {
      this.$router.push({ name: route });
    },
    getAt() {
      setTimeout(() => {
        window.location.reload(true);
      });
    },
    sair() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "home" });

      this.usuario = null;
    },
       async validate() {
      let p = true;
      for (var k in this.validate_form) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);
        if (this.form[k] == "") {
          this.validate_form[k] = " has-danger";
        } else {
          this.validate_form[k] = " has-success";
        }
      }
      for (var j in this.validate_form) {
        if (this.validate_form[j] == " has-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        await this.$store.dispatch("cliente/create_cliente", this.form);
        this.fireAlert({
          ...this.mensagem_alert,
        });
        this.modalCadastro = false;
      }
    },
    async validate2() {
      let p = true;
      for (var k in this.validate_form_login) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);
        if (this.form_login[k] == "") {
          this.validate_form_login[k] = " has-danger";
        } else {
          this.validate_form_login[k] = " has-success";
        }
      }
      for (var j in this.validate_form_login) {
        if (this.validate_form_login[j] == " has-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        await this.$store
          .dispatch("login", this.form_login)
          .then(() => {
            this.$fire({
              title: "Aviso",
              text: "Login Concluido Continue sua compra",
              type: "success",
              timer: 2000,
            });
            this.usuario = usuarioService.getUsuario() ?? null;
            this.modalLogin = false;
            this.route('index')
            location.reload();
          })
          .catch(() =>
            this.$fire({
              title: "Aviso",
              text: "Verifique se seus dados estao corretos",
              type: "Warning",
              timer: 2000,
            })
          );
      }
    },
    async cepinput() {
      this.form.cep = this.form.cep.replace(/[^0-9]/g, "")
      if (this.form.cep.length == 8) {
        await this.$store.dispatch("evento/cep", this.form.cep);
        this.form.bairro = this.cep.bairro;
        // this.form.pais = "br";
        this.form.cidade = this.cep.localidade;
        this.form.uf = this.cep.uf;
        this.form.rua = this.cep.logradouro;
      }
    },
    ra() {
      this.variableWithMaskTel =
        this.radio == 1 ? "+(##)9####-####" : "+595-###-######";
    },
  },

};
</script>
<style scoped>

</style>
