import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
// import htmlClass from "./htmlclass.module";
// import config from "./config.module";
// import breadcrumbs from "./breadcrumbs.module";
// import profile from "./profile.module";
import produtor from "./vuex/modulos/evento/produtor";
////modulos sistema
import configEmpresa from "./vuex/modulos/configEmpresa"
import evento from "./vuex/modulos/evento/evento"
import cliente from "./vuex/modulos/evento/cliente";
import bar from "./vuex/modulos/evento/bar";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        evento,
        configEmpresa,
        cliente,
        auth,
        produtor,
        bar
       


    }
});