<template>
  <div class="col-md-10">
 
  
      <h6 slot="header" class="modal-title" id="modal-title-default">
        COMPRAR INGRESSOS
      </h6>

      <div
        :class="' ' + item.cor"
        v-for="(item, index) in ingressos"
        :key="index"
      >
        <div class="row p-2 ingresso-px">
          <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 col-12 mt-2">
            {{ item.tipo }} - {{ item.tipo2 }} - {{ item.obs }}
          </div>
          <div class="row col-md-12 col-sm-12 col-lg-6 col-xs-6 col-12">
            <div
              @click="mais(index, item.valor)"
              class="col-md-1 col-sm-1 col-lg-1 col-xs-1 col-1 mt-2"
            >
              <a
                style="
                  border: 1px solid white;
                  background-color: white;
                  color: black;
                  margin-left: 5px;
                  width: 100%;
                  align-items: center;
                  padding: 2px;
                "
                size="sm"
                type="link"
                class="ml-auto mr-auto"
              >
                <i class="fas fa-plus"></i>
              </a>
            </div>
            <div
            v-if="items[index]"

              class="col-md-1 col-sm-1 col-lg-1 col-xs-1 col-1"
              style="margin-top: 5px"
            >
              <i
                class="text-center"
                style="
                  border-radius: 20px;
                  width: 20px;
                  font-weight: 900;
                  color: black;
                  border: 1px solid white;
                  background-color: white;
                  font-size: 17px;
                  padding: 0px 5px 0px 3px;
                "
              >
                {{ items[index].quant }}
              </i>
            </div>
            <div
              @click="menos(index, item.valor)"
              class="col-md-1 col-sm-1 col-lg-1 col-xs-1 col-1 mt-2"
            >
              <a
                style="
                  border: 1px solid white;
                  background-color: white;
                  color: black;
                  margin-left: 5px;
                  width: 100%;
                  align-items: center;
                  padding: 2px;
                "
                size="sm"
                type="link"
                class="ml-auto"
              >
                <i class="fas fa-minus"></i>
              </a>
            </div>
            
          </div>
          <div
          v-if="items[index]"

              class="ingresso-px2 col-md-10 col-sm-12 col-lg-7 col-xs-7 col-12 mt-1"
            >
              <div
                style="
                  font-weight: 900;
                  color: black;
                  border: 1px solid black;

                  background-color: white;
                "
                class="p-1 text-center"
              >
                {{
                  (items[index].quant == 0
                    ? 0
                    : items[index].quant * item.valor) | formatMoney
                }}
                + Taxa
                {{
                  (items[index].quant == 0
                    ? 0
                    : 
                    item.valor >= 25 ?
                    (items[index].quant * (item.valor *
                    (JSON.parse(taxa.porcent)[0] / 100))) :  + 2.5 )
                    | formatMoney
                }}
              </div>
            </div>

          <!-- <div class="col-md-1 col-sm-2 col-lg-1 col-xs-2  col-2 mt-2">
          
          </div> -->
        </div>
      </div>
      <div class="d-flex flex-row-reverse mr-3">
        <div
          class="ingresso-px2 mt-2 p-1"
          style="
            font-weight: 900;
            color: black;

            background-color: white;
          "
        >
          Sub Total {{ subTotal | formatMoney }}
        </div>
      </div>
      <div
        :class="
          usu_logado == null ? 'd-flex justify-content-between' : 'pull-right'
        "
      >
        <div>
          <n-button
            @click="showModalCompra()"
            :disabled="usu_logado == null"
            class="mr-3 mt-3"
            variant="success"
          >
            {{ usu_logado == null ? "Comprar" : "Credito" }}</n-button
          >
          <n-button
            v-if="usu_logado != null"
            :disabled="verif"
            @click="showModalCompraPix()"
            class="mr-3 mt-3"
            variant="info"
          >
            {{ usu_logado == null ? "Comprar" : "Pix" }}</n-button
          >
          <n-button
            @click="modalCadastro = true"
            v-if="usu_logado == null"
            class="mr-3 mt-3 btn-success"
            
            >Cadastre-se</n-button
          >
          <n-button
            v-if="usu_logado == null"
            @click="modalLogin = true"
            class="mr-3 mt-3 btn-primary"
            >Login</n-button
          >
        </div>
      </div>
      <modal
      v-if="modalCompra" :show.sync="modalCompra"
    style="font-weight: 700 "
    

    headerClasses="p-3"
    >
      <h6 slot="header" class="modal-title" id="modal-title-default">
        Realizar compra
      </h6>

      <div class="wrapper" style="overflow:scroll;">
        <div class="card-form">
          <div class="card-list">
            <div class="card-item" v-bind:class="{ '-active': isCardFlipped }">
              <div class="card-item__side -front">
                <div
                  class="card-item__focus"
                  v-bind:class="{ '-active': focusElementStyle }"
                  v-bind:style="focusElementStyle"
                  ref="focusElement"
                ></div>
                <div class="card-item__cover">
                  <img
                    v-bind:src="
                      'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                      currentCardBackground +
                      '.jpeg'
                    "
                    class="card-item__bg"
                  />
                </div>

                <div class="card-item__wrapper">
                  <div class="card-item__top">
                    <img
                      src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                      class="card-item__chip"
                    />
                    <div class="card-item__type">
                      <transition name="slide-fade-up">
                        <img
                          v-bind:src="
                            'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                            getCardType +
                            '.png'
                          "
                          v-if="getCardType"
                          v-bind:key="getCardType"
                          alt=""
                          class="card-item__typeImg"
                        />
                      </transition>
                    </div>
                  </div>
                  <label
                    for="cardNumber"
                    class="card-item__number"
                    ref="cardNumber"
                  >
                    <template v-if="getCardType === 'amex'">
                      <span v-for="(n, $index) in amexCardMask" :key="$index">
                        <transition name="slide-fade-up">
                          <div
                            class="card-item__numberItem"
                            v-if="
                              $index > 4 &&
                              $index < 14 &&
                              cardNumber.length > $index &&
                              n.trim() !== ''
                            "
                          >
                            *
                          </div>
                          <div
                            class="card-item__numberItem"
                            :class="{ '-active': n.trim() === '' }"
                            :key="$index"
                            v-else-if="cardNumber.length > $index"
                          >
                            {{ cardNumber[$index] }}
                          </div>
                          <div
                            class="card-item__numberItem"
                            :class="{ '-active': n.trim() === '' }"
                            v-else
                            :key="$index + 1"
                          >
                            {{ n }}
                          </div>
                        </transition>
                      </span>
                    </template>

                    <template v-else>
                      <span v-for="(n, $index) in otherCardMask" :key="$index">
                        <transition name="slide-fade-up">
                          <div
                            class="card-item__numberItem"
                            v-if="
                              $index > 4 &&
                              $index < 15 &&
                              cardNumber.length > $index &&
                              n.trim() !== ''
                            "
                          >
                            *
                          </div>
                          <div
                            class="card-item__numberItem"
                            :class="{ '-active': n.trim() === '' }"
                            :key="$index"
                            v-else-if="cardNumber.length > $index"
                          >
                            {{ cardNumber[$index] }}
                          </div>
                          <div
                            class="card-item__numberItem"
                            :class="{ '-active': n.trim() === '' }"
                            v-else
                            :key="$index + 1"
                          >
                            {{ n }}
                          </div>
                        </transition>
                      </span>
                    </template>
                  </label>
                  <div class="card-item__content">
                    <label
                      for="cardName"
                      class="card-item__info"
                      ref="cardName"
                    >
                      <div class="card-item__holder">Titular Do Cartão</div>
                      <transition name="slide-fade-up">
                        <div
                          class="card-item__name"
                          v-if="cardName.length"
                          key="1"
                        >
                          <transition-group name="slide-fade-right">
                            <span
                              class="card-item__nameItem"
                              v-for="(n, $index) in cardName.replace(
                                /\s\s+/g,
                                ' '
                              )"
                              v-show="$index === $index"
                              v-bind:key="$index + 1"
                              >{{ n }}</span
                            >
                          </transition-group>
                        </div>
                        <div class="card-item__name" v-else key="2">
                          Nome Completo
                        </div>
                      </transition>
                    </label>
                    <div class="card-item__date" ref="cardDate">
                      <label for="cardMonth" class="card-item__dateTitle"
                        >Validade</label
                      >
                      <label for="cardMonth" class="card-item__dateItem">
                        <transition name="slide-fade-up">
                          <span v-if="cardMonth" v-bind:key="cardMonth">{{
                            cardMonth
                          }}</span>
                          <span v-else key="2">MM</span>
                        </transition>
                      </label>
                      /
                      <label for="cardYear" class="card-item__dateItem">
                        <transition name="slide-fade-up">
                          <span v-if="cardYear" v-bind:key="cardYear">{{
                            String(cardYear).slice(2, 4)
                          }}</span>
                          <span v-else key="2">YY</span>
                        </transition>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item__side -back">
                <div class="card-item__cover">
                  <img
                    v-bind:src="
                      'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                      currentCardBackground +
                      '.jpeg'
                    "
                    class="card-item__bg"
                  />
                </div>
                <div class="card-item__band"></div>
                <div class="card-item__cvv">
                  <div class="card-item__cvvTitle">CVV</div>
                  <div class="card-item__cvvBand">
                    <span v-for="(n, $index) in cardCvv" :key="$index">
                      *
                    </span>
                  </div>
                  <div class="card-item__type">
                    <img
                      v-bind:src="
                        'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                        getCardType +
                        '.png'
                      "
                      v-if="getCardType"
                      class="card-item__typeImg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-form__inner">
            <div class="card-input">
              <label for="cardNumber" class="card-input__label"></label>Numero
              do Cartão
              <fg-input
                type="text"
                id="cardNumber"
                class="card-input__input"
                v-mask="generateCardNumberMask"
                v-model="cardNumber"
                v-on:focus="focusInput"
                v-on:blur="blurInput"
                data-ref="cardNumber"
                autocomplete="off"
              />
            </div>
            <div class="card-input">
              <label for="cardName" class="card-input__label"
                >Nome Impresso no Cartão</label
              >
              <fg-input
                type="text"
                id="cardName"
                class="card-input__input"
                v-model="cardName"
                v-on:focus="focusInput"
                v-on:blur="blurInput"
                data-ref="cardName"
                autocomplete="off"
              />
            </div>
            <div class="card-form__row">
              <div class="card-form__col">
                <div class="card-form__group">
                  <label for="cardMonth" class="card-input__label"
                    >Data de Validade</label
                  >
                  <b-form-select
                    class="card-input__input -select"
                    id="cardMonth"
                    v-model="cardMonth"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardDate"
                  >
                    <b-form-select-option value="" disabled selected>Mês</b-form-select-option>
                    <b-form-select-option
                      v-bind:value="n < 10 ? '0' + n : n"
                      v-for="n in 12"
                      v-bind:disabled="n < minCardMonth"
                      v-bind:key="n"
                    >
                      {{ n }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-select
                    class="card-input__input -select"
                    id="cardYear"
                    v-model="cardYear"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardDate"
                  >
                    <b-form-select-option value="" disabled selected>Ano</b-form-select-option>
                    <b-form-select-option
                      v-bind:value="$index + minCardYear"
                      v-for="(n, $index) in 12"
                      v-bind:key="n"
                    >
                      {{ $index + minCardYear }}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div class="card-form__col -cvv">
                <div class="card-input">
                  <label for="cardCvv" class="card-input__label">CVV</label>
                  <fg-input
                    type="text"
                    class="card-input__input"
                    id="cardCvv"
                    v-mask="'####'"
                    maxlength="4"
                    v-model="cardCvv"
                    v-on:focus="flipCard(true)"
                    v-on:blur="flipCard(false)"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <button
              :disabled="verif"
              @click="validate3()"
              class="card-form__button"
            >
              comprar
            </button>
          </div>
        </div>
      </div>

      <!-- <b-button
        class="pull-right mr-5 mt-3"
        @click="validate3()"
        variant="success"
        >comprar</b-button
      > -->

      <template slot="footer">
        <b-button
          size="sm"
          type="link"
          class="ml-auto"
          @click="modalCadastro = false"
          >Cancelar
        </b-button>
      </template>
    </modal>
 

    <modal
    v-if="modalCadastro" :show.sync="modalCadastro"
    style="font-weight: 700 "
    

    headerClasses="p-3"
    >
    <h6 slot="header" class="modal-title" id="modal-title-default">
        CADASTRO DE CLIENTES
      </h6>

    <div style="overflow:scroll; height:530px; width: 100%; ">

  
      <div class="form col-12 col-lg-11">
        <div class="row form-group">
          <div class="col-md-12">
            <label>Email:*</label>
            <fg-input
              required=""
              placeholder="Seu E-mail"
              v-model="form.email"
              class="no-border input-lg"
              :class="
                form.email == confirme_email
                  ? ' has-success'
                  : ' has-danger'
              "
            />
          </div>
          <div class="col-md-12">
            <label>Confirme seu email:*</label>
            <fg-input
              required=""
              placeholder="Seu E-mail"
              v-model="confirme_email"
              class="no-border input-lg"
              :class="
                form.email == confirme_email
                  ? ' has-success'
                  : ' has-danger'
              "
            />
          </div>
          <div class="col-md-6">
            <label>Senha:*</label>
            <fg-input
              required=""
              type="text"
              placeholder="Sua senha"
              v-model="form.password"
              :class="
                confirme_password == form.password
                  ? ' has-success'
                  : ' has-danger'
              "
              class="no-border input-lg"
            />
          </div>
          <div class="col-md-6">
            <label>Confirme sua Senha:*</label>
            <fg-input
              required=""
              type="password"
              placeholder="Sua senha"
              v-model="confirme_password"
              :class="
                confirme_password == form.password
                  ? ' has-success'
                  : ' has-danger'
              "
              class="no-border input-lg"
            />
          </div>
          <div class="col-md-6">
            <label>Nome:*</label>
            <fg-input
              required=""
              placeholder="O seu Nome"
              v-model="form.nome"
              :class="
                form.nome == '' ? validate_form.nome : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
          <div class="col-md-6">
            <label>Sobrenome:*</label>
            <fg-input
              required=""
              placeholder="O seu Sobrenome"
              v-model="form.sobrenome"
              :class="
                form.sobrenome == ''
                  ? validate_form.sobrenome
                  : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-6">
            <label for="cpf">CPF*</label>
            <fg-input
              required=""
              type="text"
              class="no-border input-lg"
              id="cpf"
              v-mask="variableWithMask2"
              v-model="cpf_cnpj2"
              :class="
                form.cpf == '' ? validate_form.cpf : ' has-success'
              "
              placeholder="O cpf ou Cnpj"
            />
          </div>
          <div class="col-md-6">
            <label for="telefone1">WhatsApp :*</label>
            <span v-if="radio == 1" style="font-size: 9px"
              >Obs: O 9 a mais já é colocado automaticamente
            </span>
            <fg-input
              required=""
              type="text"
              class="no-border input-lg"
              id="telefone1"
              v-mask="variableWithMaskTel"
              :class="
                form.telefone1 == ''
                  ? validate_form.telefone1
                  : ' has-success'
              "
              v-model="tel1"
              placeholder="O telefone/celular..."
            />
            <!-- <b-form-radio-group
              @input="ra"
              v-model="radio"
              label=""
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="1"
                >Brasil +55</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="2"
                >Paraguai +595</b-form-radio
              >
            </b-form-radio-group> -->
          </div>
          <div class="col-md-3 mt-2">
            <label> CEP:  </label>
            <fg-input
              placeholder="Digite seu cep"
              type="text"
              v-model="form.cep"
              :class="
                form.cep == '' ? validate_form.cep : ' has-success'
              "
              v-mask="'########'"
              class="no-border input-lg"
              @input="cepinput"
            />
            <span style="font-size: 10px;"> obs:  Somente Numeros</span>

          </div>
        

          <div class="col-md-3 mt-2">
            <label> UF:</label>
            <fg-input
              placeholder="Digite seu uf"
              v-model="form.uf"
              :class="
                form.uf == '' ? validate_form.uf : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label> Cidade:</label>
            <fg-input
              placeholder="Digite sua cidade"
              v-model="form.cidade"
              :class="
                form.cidade == ''
                  ? validate_form.cidade
                  : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>

          <div class="col-md-6 mt-2">
            <label> Bairro:</label>
            <fg-input
              placeholder="Digite seu bairro"
              v-model="form.bairro"
              :class="
                form.bairro == ''
                  ? validate_form.bairro
                  : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
          <div class="col-md-6 mt-2">
            <label> Rua:</label>
            <fg-input
              placeholder="Digite seu rua"
              v-model="form.rua"
              :class="
                form.rua == '' ? validate_form.rua : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
          <div class="col-md-3 mt-2">
            <label> Numero:</label>
            <fg-input
              placeholder="Digite numero do endereço"
              v-model="form.numero"
              :class="
                form.numero == ''
                  ? validate_form.numero
                  : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
        </div>
      </div>

    
      </div>
      <n-button
        :disabled="confirme_password != form.password"
        class="pull-right mr-5 mt-3 btn-unfo"
        @click.prevent="validate()"
        
        >Salvar</n-button
      >
      <template slot="footer">
        <n-button
          size="sm"
          type="link"
          class="ml-auto"
          @click="modalCadastro = false"
          >Cancelar
        </n-button>
      </template>
    </modal>
    <modal
      v-if="modalLogin" :show.sync="modalLogin"

      classe="p-3"
      style="font-weight: 900"
    >
      <h6 slot="header" class="modal-title" id="modal-title-default">
        Login do cliente
      </h6>

      <div class="form">
        <div class="row form-group">
          <div class="col-md-6">
            <label>Email:*</label>
            <fg-input
              required=""
              placeholder="Seu E-mail"
              v-model="form_login.email"
              class="no-border input-lg"
              :class="
                form_login.email == ''
                  ? validate_form_login.email
                  : ' has-success'
              "
            />
          </div>

          <div class="col-md-6">
            <label>password:*</label>
            <fg-input
              required=""
              type="password"
              placeholder="Sua senha"
              v-model="form_login.password"
              :class="
                form_login.password == ''
                  ? validate_form_login.password
                  : ' has-success'
              "
              class="no-border input-lg"
            />
          </div>
        </div>
      </div>

      <n-button
        class="pull-right mr-5 mt-3"
        @click="validate2()"
        variant="success"
        >Logar</n-button
      >

      <template slot="footer">
        <n-button
          size="sm"
          type="link"
          class="ml-auto"
          @click="modalCadastro = false"
          >Cancelar
        </n-button>
      </template>
    </modal>
 

    <!-- <bbt
                  @click="analits(evento.sc_interno)"
                  v-if="evento.sc_interno"
                  class="mobile bbt  text-center"
                  style="font-size: 25px"
                >
             comprar
                </bbt>
                <bbt
                  @click="analits(lista_evento.sc_externo)"
                  v-if="!evento.sc_interno"
                  class="bbt mobile  text-center"
                >
                  comprar
                </bbt> -->
  </div>
</template>
<script>
import { Button, Modal, FormGroupInput } from "@/components";

import { fireAlert } from "@/core/mixins/alertMessageMixin";
import Swal from "sweetalert2";
import usuarioService from "@/core/services/usuario.service";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  mixins: [fireAlert, moneyFormat],

  props: ["ingressos", "evento", "taxa"],
  components: {
    Modal,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput


  },
  data() {
    return {
      modalCompraPix: null,
      confirme_password: null,
      modalVendas: false,
      modalCadastro: false,
      modalLogin: false,
      modalCompra: false,
      verif: false,
      ingresso: {},
      items: [],
      quant: [],
      form: {
        email: "",
        nome: "",
        sobrenome: "",
        cpf: "",
        password: "",
        telefone1: "",
        cep: "",
        rua: "",
        numero: "",
        bairro: "",
        uf: "",
        cidade: "",
      },
      variableWithMask2: "",
      validate_form: {
        email: null,
        nome: null,
        sobrenome: null,
        cpf: null,
        password: null,
        telefone1: null,
        cep: null,
        rua: null,
        numero: null,
        bairro: null,
        cidade: null,
        uf: null,
      },

      form_login: {
        email: "",
        password: "",
      },
      validate_form_login: {
        email: null,
        password: null,
      },

      form_compra: {
        name: "",
        expiration: "",
        number: "",
        cvv: "",
      },
      validate_form_compra: {
        name: null,
        expiration: null,
        number: null,
        cvv: null,
      },
      radio: 1,
      variableWithMaskTel: "",
      tel1: null,
      cpf_cnpj2: null,
      usu_logado: usuarioService.getUsuario() ?? null,
      confirme_email: null,
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      options: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      },
      subTotal: 0,
    };
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    // document.getElementById("cardNumber").focus();
    document.addEventListener("DOMContentLoaded", function() {
  document.getElementById("cardNumber").focus();
});
  },
  computed: {
    cep() {
      return this.$store.state.evento.cep;
    },
    mensagem_alert() {
      return this.$store.state.cliente.mensagem_alert;
    },
    mensagem_alert2() {
      return this.$store.state.evento.mensagem_alert;
    },
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
  },
  created() {
    this.ingressos.forEach((element) => {
      this.items.push({
        quant: 0,
        ingressos_id: element.id,
      });
    });
  },
  mounted(){
 
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    },
    cpf_cnpj2: function () {
      this.variableWithMask2 =
        this.cpf_cnpj2.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form.cpf = this.cpf_cnpj2.replace(/[^0-9]/g, "");
    },
    tel1: function () {
      this.variableWithMaskTel =
        this.radio == 1 ? "(##)9####-####" : "+595-###-######";

      if (this.radio == 1) {
        this.form.telefone1 = this.tel1.replace(/[^0-9]/g, "");
        this.form.ddd = this.form.telefone1.substring(2, 4);
      } else {
        this.form.telefone1 = this.tel1.replace(/[^0-9]/g, "");
      }
    },
  },
  methods: {
    ra() {
      this.variableWithMaskTel =
        this.radio == 1 ? "+(##)9####-####" : "+595-###-######";
    },
    async validate3() {
      console.log(this.form_compra);
      this.form_compra.cvv = this.cardCvv;
      this.form_compra.name = this.cardName;
      this.form_compra.number = this.cardNumber;
      this.form_compra.expiration =
        this.cardMonth + "" + this.cardYear.toString().substring(2, 4);

      var form = {
        card: this.form_compra,
        itens: this.items,
        numero_parcelas: 1,
        tipo_pagamento: 2,
      };
      this.verif = true;
      await this.$store.dispatch("evento/comprar_ingresso", form);
      this.fireAlert({
        ...this.mensagem_alert2,
      });
      this.verif = false;

      this.modalCompra = false;
      this.modalVendas = false;
    },
    async validate() {
      let p = true;
      for (var k in this.validate_form) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);
        if (this.form[k] == "") {
          this.validate_form[k] = " has-danger";
        } else {
          this.validate_form[k] = " has-success";
        }
      }
      for (var j in this.validate_form) {
        if (this.validate_form[j] == " has-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        await this.$store.dispatch("cliente/create_cliente", this.form);
        this.fireAlert({
          ...this.mensagem_alert,
        });
        this.modalCadastro = false;
      }
    },
    async validate2() {
      let p = true;
      for (var k in this.validate_form_login) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);
        if (this.form_login[k] == "") {
          this.validate_form_login[k] = " has-danger";
        } else {
          this.validate_form_login[k] = " has-success";
        }
      }
      for (var j in this.validate_form_login) {
        if (this.validate_form_login[j] == " has-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        await this.$store
          .dispatch('login', this.form_login)
          .then(() => {
            this.$fire({
              title: "Aviso",
              text: "Login Concluido Continue sua compra",
              type: "success",
              timer: 2000,
            });
            window.location.reload(true);
          })
          .catch(() =>
            this.$fire({
              title: "Aviso",
              text: "Verifique se seus dados estao corretos",
              type: "Warning",
              timer: 2000,
            })
          );

        this.usu_logado = usuarioService.getUsuario() ?? null;

        this.modalLogin = false;
      }
    },
    async analits(link) {
      // await this.sleep(300);
      // this.$store.dispatch("evento/set_msn_compra", 'então bora lá' )
      // await this.sleep(2000);

      window.open(link, "_blank");
      // this.modals.modal1 = true;
      // this.$store.dispatch("evento/analitsEvento", {
      //   eventos_id: this.$route.params.id,
      // });
      // this.$store.dispatch("evento/set_msn_compra", 'COMPRAR' )
    },
    async showModalVenda(link) {
      if (link) {
        window.open(link, "_blank");
      } else {
        // this.items = ingresso;
        // await this.$store
        //     .dispatch(VERIFY_AUTH)
        this.modalVendas = true;
      }
    },
    async showModalCompra() {
      this.modalCompra = true;
    },
    async showModalCompraPix() {
      var form = {
        itens: this.items,
        tipo_pagamento: 1,
      };
      this.verif = true;

      await this.$store.dispatch("evento/comprar_ingresso", form);
      if (this.mensagem_alert2.tipo == "error") {
        this.fireAlert({
          ...this.mensagem_alert2,
        });
      } else {
        this.fireAlert({
          ...this.mensagem_alert2,
        });
      }
      await this.sleep(2000);
      if (this.mensagem_alert2.tipo != "error") {
        window.open("https://monarcapub.com.br/#/compras");
      }

      this.verif = false;

      // this.modalCompraPix = true;

      //  window.location.reload(true);
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    mais(index, valor) {
      this.items[index].quant += 1;
      let taxa
      if(valor >= 25){
        this.subTotal =
        parseFloat(valor) +
        parseFloat(valor) * (JSON.parse(this.taxa.porcent)[0] / 100) +
        this.subTotal;
      }else{
        this.subTotal =
        parseFloat(valor)  +2.5 +
        this.subTotal;
        
      }
   
    },
    menos(index, valor) {
      if (this.items[index].quant != 0) {
        this.items[index].quant -= 1;

        if(valor >= 25){
          this.subTotal =
          this.subTotal -
          (parseFloat(valor) +
            parseFloat(valor) * (JSON.parse(this.taxa.porcent)[0] / 100));
      }else{
        this.subTotal =
          this.subTotal -
          (parseFloat(valor) +2.5);
        
      }
       
      }
    },
    async cepinput() {
     this.form.cep = this.form.cep.replace(/[^0-9]/g, "")
      if (this.form.cep.length == 8) {
        await this.$store.dispatch("evento/cep", this.form.cep);
        this.form.bairro = this.cep.bairro;
        // this.form.pais = "br";
        this.form.cidade = this.cep.localidade;
        this.form.uf = this.cep.uf;
        this.form.rua = this.cep.logradouro;
      }
    },
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
  },
};
</script>



<style scoped  lang="scss">

.l-bg-cherry {
  background: linear-gradient(to right, #493240, #f09) !important;
  color: #fff;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #4286f4) !important;
  color: #fff;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #0a504a, #38ef7d) !important;
  color: #fff;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #a86008, #ffba56) !important;
  color: #fff;
}

.card .card-statistic-3 .card-icon-large .fas,
.card .card-statistic-3 .card-icon-large .far,
.card .card-statistic-3 .card-icon-large .fab,
.card .card-statistic-3 .card-icon-large .fal {
  font-size: 110px;
}

.card .card-statistic-3 .card-icon {
  text-align: center;
  line-height: 50px;
  margin-left: 15px;
  color: #000000;
  position: absolute;
  right: -5px;
  top: 20px;
  opacity: 0.2;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
  color: #fff;
}

.l-bg-orange {
  background: linear-gradient(to right, #f9900e, #ffba56) !important;
  color: #fff;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.ingresso-px {
  font-size: 14px;
  @media screen and (max-width: 560px) {
    font-size: 14px;
  }
}
.ingresso-px2 {
  font-size: 15px;
  @media screen and (max-width: 560px) {
    font-size: 14px;
  }
}

.wrapper {
  min-height: 70vh;
  // display: flex;
  width: 100%;

  // padding: 0px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }
}

.card-form {
  max-width: 90%;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
    width: 100%;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 5px;
    padding: 10px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 100%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    width: 100%;
    margin-bottom: 4px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 0px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }

}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}
bbt {
  min-width: 80px;
  min-height: 30px;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 700;
  color: #ffffff;
  background: #304b31;
  background: linear-gradient(90deg, #304b31) 0%, #304b31 100%;
  border: none;
  // border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(46, 50, 44, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.bbt:hover,
.bbt:focus {
  color: #166509;
  transform: translateY(-6px);
  background: #f8f8f3;
}

bbt:hover::before,
bbt:focus::before {
  opacity: 0.7;
}
.mobile {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
}
</style>