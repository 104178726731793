<template>
  <div class="section" id="carousel">
    <div class="container">
      <div class="title">
        <h4>Ultimos Eventos</h4>
      </div>
      <div class="row justify-content-center">
        <div class="col-11">
          <el-carousel height="500px">
            <el-carousel-item>
              <img class="d-block" height="500px" width="100%" src="https://images.unsplash.com/photo-1516450360452-9312f5e86fc7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt="First slide" />
              <div class="carousel-caption d-none d-md-block">
                <h5>Show ao vivo</h5>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" height="500px" width="100%"  src="https://images.unsplash.com/photo-1511222138462-5d03818b409c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80" alt="Second slide" />
              <div class="carousel-caption d-none d-md-block">
                <h5>Venha dançar a noite toda</h5>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" height="500px" width="100%"  src="https://images.unsplash.com/photo-1438557068880-c5f474830377?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1173&q=80" alt="Third slide" />
              <div class="carousel-caption d-none d-md-block">
                <h5>Bora sextar!!!</h5>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
};
</script>
<style></style>
