// import { forEach } from "core-js/core/array";

export const fireAlert = {
    // methods: {
    //   async fireAlert({ title, tipo, message, timer, routeName }) {
    //     (timer = tipo === "error" && !timer ? null : timer ? timer : 5000),
    //       this.$bvToast
    //         .toast(message, {
    //           title: title,
    //           variant: tipo,
    //           solid: false,
    //         })
    //         .then(() => {
    //           if (routeName && timer) {
    //             this.$router.push({ name: routeName });
    //           }
    //         });
    //   },
    // },
  
    methods: {
      async fireAlert({ title, tipo, message, timer, routeName }) {
        let msg = ''
        console.log(message)
        if (typeof message != "string") {
          for (var obj in message) {
  
              msg = obj +" " + msg 

  
          }
          msg = msg + 'campos obrigatorios'
        } else {
          msg = message
        }
        timer = tipo === 'error' && !timer ? null : timer ? timer : 5000,
          this.$fire({
            title: title ? title : "Aviso",
            text: msg,
            type: tipo ? tipo : 'success',
            timer: timer
          }).then(() => {
            if (routeName && timer) {
              this.$router.push({ name: routeName });
            }
          });
      },
    }
  };
  