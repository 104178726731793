
import ApiService from "@/core/services/api.service";


export const SET_LISTA_EVENTO_PRODUTOR = "setListeventoEventoRev";
export const SET_EVENTO = "setEvento";
export const SET_MESSAGE_ALERT = "setMessageAlert"
export const SET_LISTA_EVENTO_VENDAS = "setListEventoVendas"
export const SET_LISTA_EVENTO_VENDAS_ONLINE = 'setListEventoVendasOnline'
const actions = {
    
  
    async listar_eventos_produtor(context,value) {
        await ApiService.get('produtor/eventos/'+value)
            .then(response => {
                context.commit(SET_LISTA_EVENTO_PRODUTOR, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_eventos_vendas(context,value) {
        await ApiService.get('produtor/pdvs/relatorio/'+value)
            .then(response => {
                context.commit(SET_LISTA_EVENTO_VENDAS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_eventos_vendas_online(context,value) {
        await ApiService.get('produtor/online2/relatorio/'+value)
            .then(response => {
                context.commit(SET_LISTA_EVENTO_VENDAS_ONLINE, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    
    

    


    
   
    


};
const getters = {};

const mutations = {
    [SET_LISTA_EVENTO_PRODUTOR](state, value) {
        state.lista_eventos_produtor = value;

    },
    [SET_LISTA_EVENTO_VENDAS](state, value) {
        state.lista_eventos_vendas = value;

    },
    [SET_LISTA_EVENTO_VENDAS_ONLINE](state, value) {
        state.lista_eventos_vendas_online = value;

    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },
   

};

const state = {
    lista_eventos_produtor: [],
    lista_eventos_vendas:[],
    mensagem_alert: "",
    lista_eventos_vendas_online:[]


};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
