// import {format} from 'date-fns'
// import {ptBR} from 'date-fns/locale'
import moment from 'moment'


export default {
    filters: {
        // formatDBDateToLocale(value) {
        //     if (value.length > 10) {
        //         value = value.substr(0, 10)
        //     }

        //     if (!value) {
        //         return '--'
        //     }

        //     let [y, m, d] = value.split('-')
        //     return format(
        //         new Date(y, m - 1, d),
        //         'PP',
        //         {locale: ptBR}
        //     )
        // },
        formatEnToBr (date) {
            return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          },
          datt(value) {
            moment.locale("pt-br");
            let data = moment(value, "DD-MM-YYYY").format(
              "dddd, LL"
            );
            return data;
          },
          horat(value) {
            moment.locale("pt-br");

            let hora = moment(value, "HH:mm:ss").format(
              "HH:mm"
            );
            return hora;
          },
    }
}