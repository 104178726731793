<template>
  <navbar
    position="fixed"
    type="white"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="logo2.png" alt="" width="160" height="40" />
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Desenvolvido TitanPass
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          href="#sobrenos"
        >
          <i class="now-ui-icons business_bank"></i>
          <p>Sobre Nós</p>
        </a>
      </li> -->
      <!-- <drop-down
        tag="li"
        title="Bar"
        icon="now-ui-icons design_app"
        class="nav-item"
      >
        <nav-link to="/">
          <i class="now-ui-icons business_chart-pie-36"></i> Inicio
        </nav-link>
        <a
          href="https://demos.creative-tim.com/vue-now-ui-kit/documentation"
          target="_blank"
          class="dropdown-item"
        >
          <i class="now-ui-icons design_bullet-list-67"></i> Documentation
        </a>
      </drop-down> -->
      <!-- <drop-down
              tag="li"
              title="Examples"
              icon="now-ui-icons design_image"
              class="nav-item"
      >
         <nav-link to="/landing">
          <i class="now-ui-icons education_paper"></i> Landing
        </nav-link> 
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
      </drop-down> -->
      <li class="nav-item">
        <a class="nav-link" href="/#/login">
          <i class="now-ui-icons users_circle-08"></i>
          <p class="d-lg-none d-xl-none">login</p>

          <span v-if="usuario == null"> Acesse sua conta </span>
          <span v-else> Olá, {{ usuario.usuario.nome_sobrenome }} </span>
        </a>
      </li>
      <li class="nav-item" v-if="usuario != null">
        <a class="nav-link" @click.prevent="sair()">
          <i class="now-ui-icons users_circle-08"></i>
          <p class="d-lg-none d-xl-none"></p>

          <span>sair </span>
        </a>
      </li>
      <li class="nav-item" v-if="usuario != null">
        <a class="nav-link" href="/#/compras">
          <i class="now-ui-icons users_circle-08"></i>
          <p class="d-lg-none d-xl-none"></p>

          <span>minhas compras </span>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
import usuarioService from "@/core/services/usuario.service";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    // DropDown,
    Navbar,
    // NavLink,
    [Popover.name]: Popover,
  },
  data() {
    return {
      usu_logado: usuarioService.getUsuario() ?? true,
      usuario: usuarioService.getUsuario() ?? null,
    };
  },
  methods: {
    sair() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "index" });

      this.usuario = null;
    },
  },
};
</script>

<style scoped></style>
