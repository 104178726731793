<template>
  <div>
  <div class="page-header2 clear-filter" filter-color="black">
        <!-- <parallax class="page-header-image2">
        <video
          autoplay
          muted
          loop
          id="myVideo"
          :style="
            !check_mobile
              ? 'width: 100%; position: relative; bottom: 120vh;  '
              : ' position: relative; right:45%; bottom: 40vh;'
          "
        >
          <source
            src="https://api.boraticket.autsteeltech.com/api/v1/arquivo/banners/262"
            type="video/mp4"
          />
        </video>
      </parallax> -->
      <parallax
        class="page-header-image2"
        style="background-image: url('06.png')"
      >
      </parallax>
     
    </div>
    <div class="social-icons">
      <a href="https://api.whatsapp.com/send/?phone=%2B5567981725590&text&type=phone_number&app_absent=0" class="icon-square square-whatsapp">
        <i class="fab fa-whatsapp"></i>
      </a>
      <a href="https://t.me/monarcacg" class="icon-square square-telegram">
        <i class="fab fa-telegram"></i>
      </a>
      <a href="https://instagram.com/monarcacg?igshid=MzRlODBiNWFlZA==" class="icon-square square-instagram">
        <i class="fab fa-instagram"></i>
      </a>
    </div>
    <div class="section" style="background-image: url('04.png');
  background-size: cover; background-size: contain;
  background-position: center;
">
    <div class="pl-5 pr-5">
      <div class="row">
        <div class=" col-md-12 col-12 col-lg-12 text-center">
          <h2 class="bg-white " style="border-radius: 20px;">{{ lista_evento.nome_evento }}</h2>
        </div>
        <div v-if="lista_evento.ingressos" class="col-md-6 col-12 col-lg-6">
          <ingressos v-if="evento" :ingressos="lista_evento.ingressos" :taxa="lista_evento.taxa" :evento="lista_evento"></ingressos>

        </div>
        <div class="col-md-6 col-12 col-lg-6">
          <img :src="lista_evento.fly1" height="300px" width="100%" alt=""/>

          <div class="p-1 bg-white" style="opacity: 0.8;">
             
            
              <p class="col-md-12 col-12">
                <span class="content icon-container icon icon-fill">
                  <i class="fa fa-calendar"></i>
                </span>

                <!-- <br v-if="check_mobile"> -->

                <span
                  style="text-transform: capitalize;font-size: 14px;"
                  class="font-weight-700"
                  >{{ datt }}</span
                >
                <!-- <span  class="font-weight-700 ">{{ datt }}</span> -->
              </p>
              <p class="col-md-12 col-12">
                <span class="content icon-container icon icon-fill">
                  <i class="far fa-clock"></i>
                </span>

                <span
                  style="text-transform: capitalize;font-size: 14px;"
                  class="font-weight-700"
                  >Ás {{ horat }}</span>
                <!-- <span  class="font-weight-700 ">{{ datt }}</span> -->
              </p>

            
           

            </div>
          
        <!-- <div v-html="lista_evento.localizacao"></div> -->
        </div>
        

        
      </div>
    </div>
  
    </div>

    <!-- <signup-form></signup-form> -->
    <!-- <examples-section></examples-section>
    <download-section></download-section> -->
</div>
</template>
<script>
import { Parallax } from "@/components";
import BasicElements from "./components/BasicElementsSection";
import Navigation from "./components/Navigation";
import TabsSection from "./components/Tabs";
import ProgressPagination from "./components/ProgressPagination";
import Notifications from "./components/Notifications";
import Typography from "./components/Typography";
import JavascriptComponents from "./components/JavascriptComponents";
import CarouselSection from "./components/CarouselSection";
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";
import { Button, Modal, FormGroupInput } from "@/components";
import ingressos from "@/pages/ingressosMeu.vue";

// import { Popover, Tooltip, DatePicker } from 'element-ui';
import moment from "moment";

export default {
  name: "index",
  bodyClass: "index-page",

  components: {
    Parallax,
    ingressos,
    // BasicElements,
    // Navigation,
    // TabsSection,
    // ProgressPagination,
    // Notifications,
    // Typography,
    // JavascriptComponents,
    // CarouselSection,
    // NucleoIconsSection,
    // SignupForm,
    // ExamplesSection,
    // DownloadSection,
    // Modal,
    [Button.name]: Button,
    // [Popover.name]: Popover,
    // [Tooltip.name]: Tooltip,
    // [DatePicker.name]: DatePicker,
    // [FormGroupInput.name]: FormGroupInput
  },

  data() {
    return {
      url_p: window.location.href,

      mini: false,
      link: null,
      lista_eventos: null,
      evento: {},

      host: process.env.VUE_APP_API_URL + "arquivo/banners/",
    };
  },
  created() {
    this.init();
  },
  computed: {
    msn_compra() {
      return this.$store.state.evento.msn_compra;
    },
    // cnpjglobal() {
    //   let cpf = this.lista_evento.cpf_cnpj;

    //   if (cpf == 11) {
    //     return (
    //       cpf.slice(0, 3) +
    //       "." +
    //       cpf.slice(3, 6) +
    //       "." +
    //       cpf.slice(6, 9) +
    //       "-" +
    //       cpf.slice(9, 11)
    //     );
    //   }
    //   let x = cpf
    //     .replace(/\D/g, "")
    //     .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    //   return !x[2]
    //     ? x[1]
    //     : x[1] +
    //         "." +
    //         x[2] +
    //         "." +
    //         x[3] +
    //         "/" +
    //         x[4] +
    //         (x[5] ? "-" + x[5] : "");
    // },
    check_mobile() {
      var check = false; //wrapper no check
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    lista_evento() {
      return this.$store.state.evento.evento;
    },
    datt() {
      moment.locale("pt-br");
      let data = moment(this.lista_evento.data_evento, "DD-MM-YYYY").format(
        "dddd, LL"
      );
      return data;
    },
    horat() {
      let hora = moment(this.lista_evento.horario_evento, "HH:mm:ss").format(
        "HH:mm"
      );
      return hora;
    },
  },
  watch: {
    msn_compra() {
      this.ts = this.msn_compra;
    },
  },
  mounted() {
    // this.$store.dispatch("bar/setbar");
    this.init();
  },
  methods: {
    share() {
      if (navigator.share !== undefined) {
        navigator
          .share({
            title: this.lista_evento.nome_evento,
            text: "Acesso nosso Evento",
            url: window.location.href,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    },
    desocultar() {
      if (this.ocultador == "expander") {
        this.ocultador = "expand";
        this.ler = "Ler mais";
      } else {
        this.ler = "Ler menos";

        this.ocultador = "expander";
      }
    },
    show(evento) {
      this.evento = evento;

      this.mini = true;
      // console.log(this.mini)
    },
    async init() {
      await this.$store.dispatch("evento/listar_evento", {
        nome: this.$route.params.nome,
        id: this.$route.params.id,
      });
      // if (this.lista_evento.status != 1) {
      //   window.location.href = "https://monarca.boraticketstore.com.br/";
      // }
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    hora_pdv(abe, fec) {
      return (
        moment(abe, "HH:mm:ss").format("HH:mm") +
        " às " +
        moment(fec, "HH:mm:ss").format("HH:mm")
      );
    },
  },
};
</script>
<style>
.social-icons {
  position: fixed;
  right: 20px;
  top: 40vh;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}

.social-icons a {
  margin-bottom: 10px;
  text-decoration: none;
  color: #ffffff;
}

.social-icons i {
  font-size: 24px;
}
.icon-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  text-decoration: none;
}

.square-whatsapp {
  background-color: rgb(19, 154, 12);
}

.square-telegram {
  background-color: #1da1f2;
}

.square-instagram {
  background-color: #c13584;
}
.zoom {
  padding: 1px;
  transition: transform 0.2s; /* Animation */

  margin: 0 auto;
}
.zoom2 {
  transition: transform 0.2s; /* Animation */
}
.zoom2:hover {
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  transition: 1s;
  z-index: 1;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.415);
}

.zoom:hover {
  transform: scale(
    1.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  transition: 1s;
  z-index: 1;
}
.img-radius {
  border-radius: 15px;
}
</style>
