<template>
  <div>
    <div class="page-header2 clear-filter" filter-color="black">
      <!-- <parallax class="page-header-image2">
        <video
          autoplay
          muted
          loop
          id="myVideo"
          :style="
            !check_mobile
              ? 'width: 100%; position: relative; bottom: 120vh;  '
              : ' position: relative; right:45%; bottom: 40vh;'
          "
        >
          <source
            src="https://api.boraticket.autsteeltech.com/api/v1/arquivo/banners/262"
            type="video/mp4"
          />
        </video>
      </parallax> -->
      <parallax
        class="page-header-image2"
        style="background-image: url('06.png')"
      >
      </parallax>
    </div>
    <div class="social-icons">
      <a href="https://api.whatsapp.com/send/?phone=%2B5567981725590&text&type=phone_number&app_absent=0" class="icon-square square-whatsapp">
        <i class="fab fa-whatsapp"></i>
      </a>
      <a href="https://t.me/monarcacg" class="icon-square square-telegram">
        <i class="fab fa-telegram"></i>
      </a>
      <a href="https://instagram.com/monarcacg?igshid=MzRlODBiNWFlZA==" class="icon-square square-instagram">
        <i class="fab fa-instagram"></i>
      </a>
    </div>
    <div
      class="section"
      style="
        background-image: url('04.png');
        background-size: cover;
        background-size: contain;
        background-position: center;
      "
    >
    <div
          class="col-md-12 row"
          v-for="(ing, index2) in ingressos_pagamentos"
          :key="index2">

       <div  class="col-md-4" v-if="ing.status == 'waiting_payment'">
          <div class="p-1 card" style="box-shadow: 1px 2px #000">
            <span class="col-md-12 col-12 text-center">
              <span
                style="
                  font-size: 20px;
                  text-transform: capitalize;
                  color: black;
                "
                class="font-weight-700"
                >{{ ing.evento.nome_evento }}</span
              >
            </span>
            
         
            <div class="col-md-12 pt-2">
              <span class="content icon-container icon icon-fill">
                <i class="fa fa-map-marker"></i>
              </span>

              <span class="font-weight-700 text-uppercase">Local: </span
              >{{ ing.evento.nome_local_evento }}
              <br />
              <p class="col-md-12 col-12">
                <span class="content icon-container icon icon-fill">
                  <i class="fa fa-calendar"></i>
                </span>

                <!-- <br v-if="check_mobile"> -->

                <span
                  style="text-transform: capitalize"
                  class="font-weight-700"
                  >{{   ing.evento.data_evento | datt}}</span
                >
                <!-- <span  class="font-weight-700 ">{{ datt }}</span> -->
              </p>
              <p class="col-md-12 col-12">
                <span class="content icon-container icon icon-fill">
                  <i class="fa fa-clock-o"></i>
                </span>

                <span
                  style="text-transform: capitalize"
                  class="font-weight-700"
                  >{{  ing.evento.horario_evento | horat }}</span
                >
                <!-- <span  class="font-weight-700 ">{{ datt }}</span> -->
              </p>

 
            </div>
          </div>
          <div
            :class="' ' + item.cor"
            v-for="(item, index) in ing.ingressos"
            :key="index"
          >
            <div class="row p-2 ingresso-px">
              <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 col-12 mt-2">
     
                {{ item.tipo }} - {{ item.tipo2 }} - {{ item.obs }}
              </div>
              <div
                class="row col-md-12 col-sm-12 col-lg-12 col-xs-12 col-12 justify-content-md-center"
              >
                <span class="p-1 ml-2"
                  style="
                    font-weight: 900;
                    color: black;
                  border: 1px solid #000;

                    font-size: 14px;
                    background-color: white;
                  "
                >
                  Quantidade: {{ item.quant }}</span
                >
                <span class="p-1 ml-2"
                  style="
                  border: 1px solid #000;
                    font-weight: 900;
                    color: black;
                    font-size: 14px;
                    background-color: white;
                  "
                >
                  Valor Unitario: {{ (item.valor ) | formatMoney }}
                </span>

                <!-- <div class="col-md-1 col-sm-2 col-lg-1 col-xs-2  col-2 mt-2">
          
          </div> -->
              </div>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div
              class="mt-2 p-1 d-flex justify-content-end"
              style="
                font-weight: 900;
                color: black;
                font-size: 14px;
                background-color: white;
              "
            >
              Sub Total {{ ing.subtotal | formatMoney }}
            </div>
            <div class="mt-2 p-1 pl-1 text-center">
              <qrcode
                class=""
                :value="ing.pix_qr_code"
                :options="{ width: 200 }"
              ></qrcode>
            </div>
            <div class="card" style="box-shadow: 1px 2px #000">
              <p>{{ ing.pix_qr_code }}</p>
            </div>
            <p style="font-weight: 900;">{{ status_pay[ing.status] }}</p>

            <b-button class="mt-2" @click="copiar(ing.pix_qr_code)" pill variant="success">{{
              copia == false ? "Copiar" : "Copiado"
            }}</b-button>
          </div>
          <div></div>

        </div>
           <div 
           
          class="col-md-4"
          v-for="(item, index) in ing.ingressos"
            :key="index"
        >
         
          <b-card class="col-md-12 l-bg-cherry" v-if="ing.status == 'paid'" @click="showModalin(item, ing.evento.nome_evento )" :class="item.cor">
            
            <div class="card-statistic-3 p-2">
              <div class="card-icon card-icon-large">
                <div class="card-icon card-icon-large">
                <i style=" opacity: 0.2;font-size: 20vh; position: absolute; z-index: 0; top: 14%; right: 0px; " class="fas fa-ticket-alt"></i>

              </div>
                
              </div>
              <div class="mb-4">
                <h5 class="card-title mb-0 text-uppercase" style="color: white">
                  {{ ing.evento.nome_evento }}
<br>
              {{ item.tipo }} - {{ item.obs }}
                </h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-6">
                  <h2
                    class="d-flex align-items-center mb-0"
                    style="color: white"
                  >
                    {{ item.valor | formatMoney}}
                   
                  </h2>
                  <span style="font-size: 10px;">Transação :    {{ ing.pay_id }} </span>
                  <br>
                  <span style="font-size: 10px;">Status :    Válido </span>
                  <br>
                  
                  <span style="font-size: 10px;" class="text-capitalize"> {{ usuario.usuario.nome_sobrenome }}</span>
                <br>

                <span style="font-size: 10px;">  {{ usuario.usuario.cpf }}</span>


                </div>

                <div class="col-6 text-right text-uppercase">
                  <img style="position: absolute; bottom: 40px; left: 100px;" src="codigo-qr.gif" width="50px" alt="">

                  {{ item.tipo2 }}

                  <!-- <span>{{ item.ingressos[0].obs }}  -->
                  <!-- <i class="fas fa-arrow-up"></i> -->
                  <!-- </span> -->
                </div>
              </div>
              <!-- <div class="progress mt-1" data-height="8" style="height: 8px">
              <div
                class="progress-bar l-bg-orange"
                role="progressbar"
                data-width="25%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 25%"
              ></div>
            </div> -->
            </div>
          </b-card>
        </div>
      </div>
      <div
   
       
      >

        <div   v-if="modalin"      class="modal-dialog2" >

          <div class="d-flex justify-content-md-center"  >
            <b-card class="col-md-12" :class="ing_p.cor">
      <b-button  class="" @click="modalin_close()">fechar</b-button>


              <div
                style="position: relative; top: 20%"
                class="card-statistic-3"
              >
                <div class="card-icon card-icon-large">
                  <i class="fas fa-ticket-alt"></i>
                </div>

                <div class="mb-4" >
                  <h5
                    class="card-title mb-0 text-uppercase"
                    style="color: white"
                  >
                    <span class="col-md-12 col-12 text-center">
                      <span
                        style="
                          font-size: 20px;
                          text-transform: capitalize;
                          color: white;
                        "
                        class="font-weight-700"
                        >{{ nome_evento }}</span
                      >
                      <br />
                    </span>
                    {{ ing_p.tipo }} - {{ ing_p.obs }}
                  </h5>
                </div>
                <div class="row align-items-center d-flex">
                  <div class="col-9">
                    <span style="font-size: 14px" class="text-capitalize">
                      {{ usuario.usuario.nome_sobrenome }}</span
                    >
                    <br />

                    <span style="font-size: 14px">
                      {{ usuario.usuario.cpf }}</span
                    >
                  </div>
                </div>

                <!-- <div class="progress mt-1" data-height="8" style="height: 8px">
              <div
                class="progress-bar l-bg-orange"
                role="progressbar"
                data-width="25%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                style="width: 25%"
              ></div>
            </div> -->
              </div>
              <vue-qr
                style="position: relative; top: 30%"
                logoSrc="logo.png"
                :text="String(ing_p.qrcode)"
                :size="320"
              ></vue-qr>
            </b-card>
          </div>

        </div>
      </div>
      <modal
        :show.sync="modalLogin"
        body-classes="p-3"
        modal-classes="modal-dialog-centered modal-lg "
        style="font-weight: 900"
      >
        <h6 slot="header" class="modal-title" id="modal-title-default">
          Login do cliente
        </h6>

        <div class="form">
          <div class="row form-group">
            <div class="col-md-6">
              <label>Email:*</label>
              <input
                required=""
                placeholder="Seu E-mail"
                v-model="form_login.email"
                class="form-control"
                :class="
                  form_login.email == ''
                    ? validate_form_login.email
                    : 'border-2 border-success'
                "
              />
            </div>

            <div class="col-md-6">
              <label>password:*</label>
              <input
                required=""
                type="password"
                placeholder="Sua senha"
                v-model="form_login.password"
                :class="
                  form_login.password == ''
                    ? validate_form_login.password
                    : 'border-2 border-success'
                "
                class="form-control"
              />
            </div>
          </div>
        </div>

        <b-button
          class="pull-right mr-5 mt-3"
          @click="validate2()"
          variant="success"
          >Logar</b-button
        >

        <template slot="footer">
          <b-button
            size="sm"
            type="link"
            class="ml-auto"
            @click="modalCadastro = false"
            >Cancelar
          </b-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import usuarioService from "@/core/services/usuario.service";
import ingressoService from "@/core/services/ingresso.service";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";
import moment from "moment";
import VueQr from "vue-qr";

// import { LOGIN, LOGOUT, VERIFY_AUTH } from "@/core/store/auth.module";
// import QrcodeVue from "qrcode.vue";
export default {
  mixins: [moneyFormat, dateFormat],

  components: {
    Modal,
    VueQr,
  },
  data() {
    return {
      status_pay: {
        waiting_payment: "AGUARDANDO PAGAMENTO",
        pad: "PAGO",
        refunded: "ESTORNO",
      },
      modalLogin: false,
      modalin: false,
      form_login: {
        email: "",
        password: "",
      },
      validate_form_login: {
        email: null,
        password: null,
      },
      qrcode: null,
      size: 100,
      copia: false,
      ing_p: {},
      nome_evento: null,
    };
  },
  created() {
    this.listar_ingressos_pix();
    moment.locale("pt-br");
  },
  computed: {
    usuario() {
      return usuarioService.getUsuario() ?? null;
    },
    ingressos_pagamentos() {
      return this.$store.state.evento.ingresso_pix;
    },
    check_mobile() {
      var check = false; //wrapper no check
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
  methods: {
    modalin_close(){
      this.modalin= false
      console.log(this.modalin)
    },
    copiar(text) {
      this.copia = true;

      navigator.clipboard.writeText(text);
    },
    showModalin(value, nome) {
      console.log(value);
      this.nome_evento = nome;
      this.ing_p = value;

      this.modalin = true;
    },
    async listar_ingressos_pix() {
      if (this.usuario == null) {
        this.modalLogin = true;
      } else {
        await this.$store.dispatch(
          "evento/listar_ingressos_pix",
          this.usuario.usuario_id
        );
        this.react_pay();

        // ingressoService.saveIngresso(this.ingressos_cliente)
      }
    },
    async react_pay() {
      let reply = false;

      while (reply) {
        var ing = this.ingressos_pagamentos;
        ing.forEach((element) => {
          if (element.status == "waiting_payment") {
            reply = true;
            console.log(ing);
          } else {
            let reply = false;
          }
        });

        await this.sleep(15000);
        await this.$store.dispatch(
          "evento/listar_ingressos_pix",
          this.usuario.usuario_id
        );
        // this.$fire({
        //   title: "Aviso",
        //   text: "Compra realizada com sucesso",
        //   type: "success",
        //   timer: 2000,
        // });
      }
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async validate2() {
      let p = true;
      for (var k in this.validate_form_login) {
        //  console.log("Chave: " + k + ", Valor: " + this.form[k]);
        if (this.form_login[k] == "") {
          this.validate_form_login[k] = "border-2 border-danger";
        } else {
          this.validate_form_login[k] = "border-2 border-success";
        }
      }
      for (var j in this.validate_form_login) {
        if (this.validate_form_login[j] == "border-2 border-danger") {
          p = false;
          console.log(j);
        }
      }

      if (p == true) {
        await this.$store
          .dispatch('login', this.form_login)
          .then(() => {
            this.$fire({
              title: "Aviso",
              text: "Login Concluido Continue sua compra",
              type: "success",
              timer: 2000,
            });
            this.listar_ingressos_cliente();

            this.usu_logado = usuarioService.getUsuario();

            this.modalLogin = false;
          })
          .catch(() =>
            this.$fire({
              title: "Aviso",
              text: "Verifique se seus dados estao corretos",
              type: "Warning",
              timer: 2000,
            })
          );
      }
    },
  },
};
</script>


<style scoped>
.l-bg-cherry {
  background: linear-gradient(to right, #493240, #f09) !important;
  color: #fff;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #4286f4) !important;
  color: #fff;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #0a504a, #38ef7d) !important;
  color: #fff;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #a86008, #ffba56) !important;
  color: #fff;
}
.social-icons a {
  margin-bottom: 10px;
  text-decoration: none;
  color: #ffffff;
}
.social-icons i {
  font-size: 24px;
}
.icon-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  text-decoration: none;
}

.square-whatsapp {
  background-color: rgb(19, 154, 12);
}

.square-telegram {
  background-color: #1da1f2;
}

.square-instagram {
  background-color: #c13584;
}
.im2 {
  border-radius: 20px;
}

.card32 {
  background: rgb(255, 255, 255);

  border: 2px solid rgb(166, 166, 166);
  border-radius: 20px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
}

.card32:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media screen and (min-width: 890px) {
  .bory {
    min-height: 70%;
  }
  .data-mes {
    font-size: 15px;
  }
  .data-semana {
    font-size: 13px;
  }
  .padd {
    padding: 0px 10px 0px 10px;
  }
  .texto-cel {
    font-size: 22px;
  }
  .texto-cel2 {
    color: black;

    font-size: 16px;
  }
  .texto-cel3 {
    color: black;

    font-size: 16px;
  }

  .img-cel {
    width: 100%;
  }
  .margin-cel {
    margin-top: 20px;
  }
  .face {
    width: 100%;
    height: 230px;
    transition: 0.9s;
  }
  .face1 {
    position: relative;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 10px;
    transform: translateY(130px);
  }
  .face.face2 {
    position: relative;
    background: rgb(255, 255, 255);
    top: 20px;
    color: rgb(238, 242, 245);
    align-items: center;
    /* justify-content: center; */
    /* padding: 20px; */
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.8);
    transform: translateY(-60px);
  }
  .minecardheight {
    width: 6rem;
    box-shadow: 1px 1px 1px black;
    font-weight: 700;
    font-size: 10px;
  }
}

@media screen and (max-width: 770px) {
  .data-mes {
    font-size: 11px;
  }
  .data-semana {
    font-size: 7px;
  }
  .padd {
    padding: 10px;
  }
  .texto-cel {
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
  }
  .texto-cel2 {
    color: black;
    font-size: 8px;
    line-height: -10px;
  }
  .texto-cel3 {
    color: black;

    font-size: 8px;
  }
  .img-cel {
    height: 200px;
    width: 100%;
  }
  .card3:checked .face.face2 h3 {
    transform: translateY(0);
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }

  .face {
    width: 100%;
    height: 135px;
    transition: 0.9s;
  }
  .face1 {
    position: relative;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 10px;
    transform: translateY(65px);
  }

  .face.face2 {
    position: relative;
    background: rgb(255, 255, 255);
    top: 20px;
    color: rgb(238, 242, 245);
    align-items: center;
    /* justify-content: center; */
    /* padding: 20px; */
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.8);
    transform: translateY(-87px);
  }
  .minecardheight {
    width: 3rem;
    box-shadow: 1px 1px 1px black;
    font-weight: 700;
    font-size: 8px;
  }
}
/* body {
  display: flex;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #444;
  justify-content: center;
   align-items: center;
  font-family: arial;
} */
/* .container {
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
} */
.card3 {
  width: 100%;
  margin-block: 2px;
  position: relative;
}

.card3:hover .face.face1 {
  transform: translateY(0);
  /* box-shadow: inset 0 0 60px whitesmoke, inset 20px 0 80px rgb(3, 4, 3),
    inset -20px 0 80px rgba(0, 0, 0, 0.625), inset 20px 0 300px rgb(0, 0, 0),
    inset -20px 0 300px rgb(0, 0, 0), 0 0 50px rgb(7, 8, 6), -10px 0 80px rgb(19, 19, 19),
    10px 0 80px rgb(13, 21, 18); */
}
.face.face1 .content {
  opacity: 0.3;
  transition: 0.2s;
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
}
.card3:hover .face.face1 .content {
  opacity: 1;
}
.i {
  font-size: 3em;
  color: white;
  display: inline-block;
}
.h3 {
  font-size: 1.8em;
  color: rgb(15, 15, 15);
  font-weight: 600;
}
.face.face2 .content a {
  transition: 0.1s;
}

.card3:hover .face.face2 {
  transform: translateY(0);
  background: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}
@media screen and (min-width: 770px) {
  .card3:hover .face.face2 h3 {
    transform: translateY(0);
    background: rgb(255, 255, 255);
    color: rgb(253, 253, 253);
  }
}
p,
a {
  font-size: 10pt;
  margin: 0;
  padding: 0;
  color: #333;
}
a {
}
a:hover {
  background: #333;
  color: whitesmoke;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.ba {
  position: absolute;
  background: #3f3737;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  /* background-image: url("https://boraticketstore.com.br/wp-content/themes/boraticket/assets/img/xfooter-bg.webp.pagespeed.ic.bRCEzz3dJO.webp"); */

  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

 .modal-dialog2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-button {
  /* Estilize o botão de fechamento como desejar */
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

</style> 
