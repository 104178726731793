<template>
  <div>
    <div class="page-header2 clear-filter" filter-color="black" >
      <!-- <parallax class="page-header-image2">
        <video
          autoplay
          muted
          loop
          id="myVideo"
          :style="
            !check_mobile
              ? 'width: 100%; position: relative; bottom: 120vh;  '
              : ' position: relative; right:45%; bottom: 40vh;'
          "
        >
          <source
            src="https://api.boraticket.autsteeltech.com/api/v1/arquivo/banners/262"
            type="video/mp4"
          />
        </video>
      </parallax> -->
      <parallax
        class="page-header-image2"
        style="background-image: url('06.png')"
      >
      </parallax>
     
      <div class="container">
        <div class="content-center brand">
          <!-- <img style="border:2px solid rgba(39, 15, 59, 0.557);"  src="logo2.png" width="300" alt="" /> -->
          <!-- <h1 style="font-weight: 500" class="h1-seo">{{ bar.title }}</h1> -->
          <h3 style="font-weight: 400" class="text-uppercase">
            <!-- {{ bar.subtitle }} -->
          </h3>
        </div>
        <!-- <h6 class="category category-absolute">
          Oferecimento
          <a href="http://invisionapp.com/" target="_blank">
            <img width="23px" src="logo.png" class="invision-logo" />
             </a
          >. BoraTicketStore e alt steel tech
          <a href="https://www.creative-tim.com" target="_blank"> </a>.
        </h6> -->
      </div>
    </div>
    <div class="social-icons">
      <a href="https://api.whatsapp.com/send/?phone=%2B5567981725590&text&type=phone_number&app_absent=0" class="icon-square square-whatsapp">
        <i class="fab fa-whatsapp"></i>
      </a>
      <a href="https://t.me/monarcacg" class="icon-square square-telegram">
        <i class="fab fa-telegram"></i>
      </a>
      <a href="https://instagram.com/monarcacg?igshid=MzRlODBiNWFlZA==" class="icon-square square-instagram">
        <i class="fab fa-instagram"></i>
      </a>
    </div>

    <!-- <n-button type="warning" @click.native="mini = true">
            Launch Modal Mini
          </n-button> -->
    <!-- <div class="main">
      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/hero-image-1.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/hero-image-2.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/hero-image-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <basic-elements></basic-elements> -->
    <!-- <navigation></navigation> -->
    <!-- <tabs-section></tabs-section> -->
    <!-- <progress-pagination></progress-pagination> -->
    <!-- <notifications></notifications> -->
    <!-- <typography></typography> -->
    <!-- <javascript-components></javascript-components> -->
    <div class="section" style="background-image: url('04.png');
  background-size: cover; background-size: contain;
  background-position: center;
">
      <div class="pl-5 pr-5">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-12">
            <div class="row">
              <!-- <div class="col-md-12">
                <h1>Entradas</h1>
              </div> -->
              <div
                v-for="(item, index) in lista_eventos"
                :key="index"
                class="col-md-4 col-lg-4 col-sm-4 col-12 pt-1 "
              >
                <div class="card zoom2">
                  <img
                    v-lazy="item.ini_arq"
                    @click="show(item)"
                    style=" max-height: 400px; width: 100%"
                  />
                  <div class="p-3 text-center">
                    <span style="font-size: 16px; font-weight: 700">
                      {{ item.nome_evento }}
                    </span>
                    <br>
                    <span style="font-size:16px; font-weight: 500"
                      >{{ item.d }} ás {{ item.m }}
                    </span>
                    <br />
                    <span class="">
                <span class="content icon-container icon icon-fill">
                  <i style="color: rgb(26, 152, 110);" class="fa fa-map-marker"></i>
                </span>

                {{ item.nome_local_evento }}-
              {{ item.endereco }}
          

            
</span>
                    
                    
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section">
      <div class="container">
        <div class="row justify-content-md-center">
          <div
            class="col-md-12 col-lg-12"
            v-for="(item, index) in hostorico"
            :key="index"
          >
            <div class="row pb-3 pt-3">
              <div class="col-md-3">
                <h4>{{ item.nome_evento }}</h4>
              </div>

              <div
                v-for="(item2, index2) in item.eventos"
                :key="index2"
                class="col-md-4 zoom"
              >
                <img :src="item2.link" class="img-fluid img-radius" />
              </div>
            </div>
            <hr style="border: 2px solid black" />
          </div>
        </div>
      </div>
    </div> -->

    <!-- <carousel-section></carousel-section> -->
    <!-- <nucleo-icons-section></nucleo-icons-section> -->
    <div id="sobrenos" class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">{{ bar.title_sobrenos }}</h2>
            <h5 class="description">
              {{ bar.detalhe_sobrenos }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <!-- <signup-form></signup-form> -->
    <!-- <examples-section></examples-section>
    <download-section></download-section> -->
    <modal v-if="mini" :show.sync="mini" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ evento.nome_evento }}</h4>
      <div class="row">
        <div class="col-md-7 col-12">
          <div class="p-1 card" style="font-size: 12px">
            <p class="col-md-12 col-12">
              <span
                style="text-transform: uppercase; color: black"
                class="font-weight-700"
              >
                {{ evento.generos }}
              </span>
            </p>
        
              <div class="pl-3">
              <span class="content icon-container icon icon-fill">
                <i style="color: rgb(0, 0, 0); font-size: 15px;" class="fa fa-child mr-1"></i>
              </span>
              <span  style="font-size: 15px; font-weight: 600">Classificação: </span>
              <span style="font-size: 15px;" >{{ evento.classificacao }}</span>
            </div>
            <div class="pl-3">
              <i style="color: rgb(0, 0, 0); font-size: 15px;"  class="far fa-clock mr-1"></i>

                    <span style="text-transform: capitalize; font-size: 15px; font-weight: 600"
                      >{{ evento.d }} ás {{ evento.m }}
                    </span>
                    <br />
                 
                    <span class="">
                <span class="content icon-container icon icon-fill">
                  <i style="color: rgb(26, 152, 110);" class="fa fa-map-marker"></i>
                </span>

                {{ evento.nome_local_evento }}-
              {{ evento.endereco }}
          

            
</span>
                  </div>

            <!-- <div  style="overflow: auto;" :style="check_mobile ? 'height: 300px'  :'height: 400px'">
              <div  v-html="evento.localizacao"></div>
            </div> -->
          </div>
        </div>
        <div class="col-md-5 col-12">
          <img  v-lazy="evento.fly1" style="border-radius: 20px; width: 100%; max-height: 200px;" />
        </div>
      </div>

      <ingressos v-if="evento" :ingressos="evento.ingressos" :taxa="evento.taxa" :evento="evento"></ingressos>


      <template slot="footer">
        <!-- <n-button>Ir para pagina do evento</n-button> -->
        <n-button type="danger" @click.native="mini = false">Fechar</n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { Parallax } from "@/components";
import BasicElements from "./components/BasicElementsSection";
import Navigation from "./components/Navigation";
import TabsSection from "./components/Tabs";
import ProgressPagination from "./components/ProgressPagination";
import Notifications from "./components/Notifications";
import Typography from "./components/Typography";
import JavascriptComponents from "./components/JavascriptComponents";
import CarouselSection from "./components/CarouselSection";
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";
import { Button, Modal, FormGroupInput } from "@/components";
import ingressos from "@/pages/ingressosMeu.vue"

// import { Popover, Tooltip, DatePicker } from 'element-ui';
import moment from "moment";

export default {
  name: "index",
  bodyClass: "index-page",

  components: {
    Parallax,
    ingressos,
    // BasicElements,
    // Navigation,
    // TabsSection,
    // ProgressPagination,
    // Notifications,
    // Typography,
    // JavascriptComponents,
    // CarouselSection,
    // NucleoIconsSection,
    // SignupForm,
    // ExamplesSection,
    // DownloadSection,
    Modal,
    [Button.name]: Button,
    // [Popover.name]: Popover,
    // [Tooltip.name]: Tooltip,
    // [DatePicker.name]: DatePicker,
    // [FormGroupInput.name]: FormGroupInput
  },

  data() {
    return {
      mini: false,
      link: null,
      lista_eventos: null,
      evento: {},

      hostorico: [
        {
          nome_evento: "evento 20/03/2023",
          eventos: [
            {
              link: "https://momentonoiva.com.br/dicas/wp-content/uploads/2016/11/bar2-1050x700.jpg",
            },
            {
              link: "https://img.elo7.com.br/product/main/1F9990C/banner-festa-bar-bar02-tematicas.jpg",
            },
            {
              link: "https://vejasp.abril.com.br/wp-content/uploads/2016/12/alt-tab.jpg?quality=70&strip=info&w=1000&h=667&crop=1",
            },
            {
              link: "https://momentonoiva.com.br/dicas/wp-content/uploads/2016/11/bar2-1050x700.jpg",
            },
            {
              link: "https://img.elo7.com.br/product/main/1F9990C/banner-festa-bar-bar02-tematicas.jpg",
            },
          ],
        },
        {
          nome_evento: "Festa 27/04/2023",
          eventos: [
            {
              link: "https://momentonoiva.com.br/dicas/wp-content/uploads/2016/11/bar2-1050x700.jpg",
            },
            {
              link: "https://img.elo7.com.br/product/main/1F9990C/banner-festa-bar-bar02-tematicas.jpg",
            },
            {
              link: "https://vejasp.abril.com.br/wp-content/uploads/2016/12/alt-tab.jpg?quality=70&strip=info&w=1000&h=667&crop=1",
            },
            {
              link: "https://momentonoiva.com.br/dicas/wp-content/uploads/2016/11/bar2-1050x700.jpg",
            },
            {
              link: "https://img.elo7.com.br/product/main/1F9990C/banner-festa-bar-bar02-tematicas.jpg",
            },
          ],
        },
      ],
      // eventos: [
      //   {
      //     nome_evento: "Eventos",
      //     links: [
      //       {
      //         link: "eventos/evento1.png",
      //       },
      //       {
      //         link: "eventos/evento2.png",
      //       },
      //       {
      //         link: "eventos/evento3.png",
      //       },
      //     ],
      //   },
      // ],
      host: process.env.VUE_APP_API_URL + "arquivo/banners/",
    };
  },
  created() {
    this.preenxer();
  },
  computed: {
    check_mobile() {
      var check = false; //wrapper no check
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    eventos() {
      return this.$store.state.evento.lista_eventos;
    },
    bar() {
      return this.$store.state.bar.bar;
    },
  },
  mounted() {
    this.$store.dispatch("bar/setbar");
  },
  methods: {
    show(evento) {
      this.$router.push({
        name: "evento",
        params: { nome: evento.nome_evento.replace(/ /g, "-"), id: evento.id },
      });
      // this.evento = evento;

      // this.mini = true;
      // console.log(this.mini)
    },
    async preenxer() {
      // if(  window.navigator.onLine == 'off'){
      //   this.$router.push({
      //   name: "ingressos",
      // })
      // }
      await this.$store.dispatch("evento/listar_eventos");
      // await this.$store.dispatch("evento/analits ");
      this.lista_eventos = this.eventos;
      console.log(this.lista_eventos);

      // this.$store.dispatch("evento/listar_evento_limpar");

      moment.locale("pt-br");
      for (let v = 0; v < this.lista_eventos.length; v++) {
        this.lista_eventos[v].d = moment(
          this.lista_eventos[v].data_evento,
          "DD-MM-YYYY"
        ).format("dddd, LL");
        this.lista_eventos[v].m = moment(
          this.lista_eventos[v].horario_evento,
          "HH:mm:ss"
        ).format("HH:mm");
        this.lista_eventos[v].d2 = moment(
          this.lista_eventos[v].d2,
          "DD-MM-YYYY"
        ).format("D");
        this.lista_eventos[v].data_mais = moment(
          this.lista_eventos[v].data_mais,
          "DD-MM-YYYY"
        ).format("ddd");
        this.lista_eventos[v].data_evento = moment(
          this.lista_eventos[v].data_evento,
          "DD-MM-YYYY"
        ).format("dddd");
      }

      // this.imageBase64();
    },
  },
};
</script>
<style>
.social-icons {
  position: fixed;
  right: 20px;
  top: 40vh;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}

.social-icons a {
  margin-bottom: 10px;
  text-decoration: none;
  color: #ffffff;
  
}

.social-icons i {
  font-size: 24px;
}
.icon-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  text-decoration: none;
}

.square-whatsapp {
  background-color: rgb(19, 154, 12);
}

.square-telegram {
  background-color: #1da1f2;
}

.square-instagram {
  background-color: #c13584;
}
.zoom {
  padding: 1px;
  transition: transform 0.2s; /* Animation */

  margin: 0 auto;
}
.zoom2 {
  transition: transform 0.2s; /* Animation */

}
.zoom2:hover {
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  transition: 1s;
  z-index: 1;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.415);
}

.zoom:hover {
  transform: scale(
    1.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  transition: 1s;
  z-index: 1;
}
.img-radius {
  border-radius: 15px;
}
</style>
